import React, { useEffect, useState } from 'react';
import Img from '../../../images/logo.png';
import axios from 'axios';
import { Config } from '../../../config';
import { Spin } from 'antd';
import { getLocalStorage, Notifications } from '../../../utils';

function Settings() {
  const config = Config();
  const [rates, setRates] = useState({
    interest: '',
    insuarance: '',
    application_fee: '',
    arrangement_fee: '',
    termination_fee: '',
    government_tax: '',
    gross_loan: '',
  });

  const [messages, setMessages] = useState({
    completion_msg: '',
    welcome_msg: '',
    welcome_footer: '',
    talk_to_agent_msg: '',
    private_sector_msg: '',
    sme_msg: '',
    zna_msg: '',
    calculator_msg:'',
  });

  const [faqs, setFaqs] = useState({
    interest: '',
    procesing_time: '',
    loan_requirements: '',
  });
  
  const [passwords, setPasswords] = useState({
    old_password: '',
    new_password: '',
    confirm_password: ''
  });
  const [loading, setLoading] = useState(true);
  const isMasterUser = getLocalStorage("userInfo").user_type === 'master_user';
  const passwordStatus = isMasterUser ? true : false;


  const headers = {
    headers: {
      Authorization: `Bearer ${config.token}`,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };

  useEffect(() => {
    getRates();
    getFaqs()
    getMessages()
  }, []);

  const getRates = () => {
    let url = `${config.base_url}rates/1/`;
    axios
      .get(url, headers)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setRates(res.data[0]);
        }
      })
      .catch((error) => {
        setLoading(false);
        setRates(null);
      });
  };


  const getFaqs = () => {
    let url = `${config.base_url}faqs/1/`;
    axios
      .get(url, headers)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setFaqs(res.data[0]);
        }
      })
      .catch((error) => {
        setLoading(false);
        setFaqs(null);
      });
  };

  const getMessages = () => {
    let url = `${config.base_url}messages/1/`;
    axios
      .get(url, headers)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setMessages(res.data[0]);
        }
      })
      .catch((error) => {
        setLoading(false);
        setFaqs(null);
      });
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRates({ ...rates, [name]: value });
  };

  const handleFaqsInputChange = (e) => {
    const { name, value } = e.target;
    setFaqs({ ...faqs, [name]: value });
  };

  const handleMessageInputChange = (e) => {
    const { name, value } = e.target;
    setMessages({ ...messages, [name]: value });
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });
  };

  const handleSubmitRates = (e) => {
    setLoading(true);
    e.preventDefault();
    let url = `${config.base_url}rates/1/`;
    axios
      .put(url, rates, headers)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          Notifications('success', 'Success', 'Rates updated successfully');
        }
      })
      .catch((error) => {
        setLoading(false);
        Notifications('error', 'Error', 'Failed to update rates.');
      });
  };
  

  const handleSubmitFaqs = (e) => {
    setLoading(true);
    e.preventDefault();
    let url = `${config.base_url}faqs/1/`;
    axios
      .put(url, faqs, headers)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          Notifications('success', 'Success', 'FAQS updated successfully');
        }
      })
      .catch((error) => {
        setLoading(false);
        Notifications('error', 'Error', 'Failed to update FAQS.');
      });
  };

  const handleSubmitMessages = (e) => {
    setLoading(true);
    e.preventDefault();
    let url = `${config.base_url}messages/1/`;
    axios
      .put(url, messages, headers)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          Notifications('success', 'Success', 'Messages updated successfully');
        }
      })
      .catch((error) => {
        setLoading(false);
        Notifications('error', 'Error', 'Failed to update Messages.');
      });
  };

  const handleSubmitPassword = (e) => {
    e.preventDefault();
  
    if (passwords.new_password !== passwords.confirm_password) {
      Notifications('error', 'Error', 'New passwords do not match.');
      return;
    }
  
    setLoading(true);
    let url = `${config.base_url}change_password/`;
    axios
      .post(
        url,
        {
          old_password: passwords.old_password,
          new_password: passwords.new_password,
          confirm_password: passwords.confirm_password,
        },
        headers
      )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          Notifications('success', 'Success', 'Password changed successfully');
          setPasswords({
            old_password: '',
            new_password: '',
            confirm_password: '',
          });
        }
      })
      .catch((error) => {
        setLoading(false);

        if (error.response && error.response.data && error.response.data.errors) {
          const errorMessages = error.response.data.errors;
          
          // Handle multiple error fields
          for (const field in errorMessages) {
            if (errorMessages.hasOwnProperty(field)) {
              Notifications('error', 'Error', `${field}: ${errorMessages[field].join(' ')}`);
            }
          }
        } else {
          Notifications('error', 'Error', 'An error occurred while changing the password. NB You cannot use a password you once used before');
        }
      });
  };

  
  return (
    <section className="section profile">
      <div className="row">
        <div className="col-xl-4">
          <div className="card">
            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
              <img src={Img} alt="Logo" className="rounded-circle" />
            </div>
          </div>
        </div>

        <div className="col-xl-8">
          <div className="card">
            <div className="card-body pt-3">
              <ul className="nav nav-tabs nav-tabs-bordered">
                {isMasterUser &&(
                  <>
                <li className="nav-item">
                  <button
                    className="nav-link active"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-overview"
                  >
                    Overview
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-edit"
                  >
                    Edit Rates
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#faqs-edit"
                  >
                    Edit FAQs
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#messages-edit"
                  >
                    Edit Messages
                  </button>
                </li>
                </>
                )}
                <li className="nav-item">
                  <button
                    className="nav-link"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-change-password"
                  >
                    Change Password
                  </button>
                </li>
              </ul>

              <div className="tab-content pt-2">
                {isMasterUser && (
                <>
                {rates ? (
                  <div
                    className="tab-pane fade show active profile-overview"
                    id="profile-overview"
                  >
              

                    <h5 className="card-title">Rates Details</h5>

                    <div className="row">
                      <div className="col-lg-3 col-md-4 label">
                        Interest Rate
                      </div>
                      <div className="col-lg-9 col-md-8">{rates.interest}</div>
                    </div>

                    <div className="row">
                      <div className="col-lg-3 col-md-4 label">
                        Insurance Rate
                      </div>
                      <div className="col-lg-9 col-md-8">
                        {rates.insuarance}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-3 col-md-4 label">
                        Application Fee
                      </div>
                      <div className="col-lg-9 col-md-8">
                        {rates.application_fee}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-3 col-md-4 label">
                        Arrangement Fee
                      </div>
                      <div className="col-lg-9 col-md-8">
                        {rates.arrangement_fee}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-3 col-md-4 label">
                        Termination Fee
                      </div>
                      <div className="col-lg-9 col-md-8">
                        {rates.termination_fee}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-3 col-md-4 label">
                        Government Tax
                      </div>
                      <div className="col-lg-9 col-md-8">
                        {rates.government_tax}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-3 col-md-4 label">Gross Loan</div>
                      <div className="col-lg-9 col-md-8">{rates.gross_loan}</div>
                    </div>
                  </div>
                ) : (
                  <p>No rate information available.</p>
                )}
               
                <div className="tab-pane fade profile-edit pt-3" id="profile-edit">
                  {rates ? (
                    <form onSubmit={handleSubmitRates}>
                       <Spin spinning={loading}>
                      

                      <div className="row mb-3">
                        <label
                          htmlFor="interest"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Interest Rate
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <input
                            name="interest"
                            type="text"
                            className="form-control"
                            id="interest"
                            value={rates.interest || ''}
                            onChange={handleInputChange}
                            
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="insurance"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Insurance Rate
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <input
                            name="insuarance"
                            type="text"
                            className="form-control"
                            id="insurance"
                            value={rates.insuarance || ''}
                            onChange={handleInputChange}
                            
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="application_fee"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Application Fee
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <input
                            name="application_fee"
                            type="text"
                            className="form-control"
                            id="application_fee"
                            value={rates.application_fee || ''}
                            onChange={handleInputChange}
                            
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="arrangement_fee"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Arrangement Fee
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <input
                            name="arrangement_fee"
                            type="text"
                            className="form-control"
                            id="arrangement_fee"
                            value={rates.arrangement_fee || ''}
                            onChange={handleInputChange}
                            
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="termination_fee"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Termination Fee
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <input
                            name="termination_fee"
                            type="text"
                            className="form-control"
                            id="termination_fee"
                            value={rates.termination_fee || ''}
                            onChange={handleInputChange}
                            
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="government_tax"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Government Tax
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <input
                            name="government_tax"
                            type="text"
                            className="form-control"
                            id="government_tax"
                            value={rates.government_tax || ''}
                            onChange={handleInputChange}
                            
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="gross_loan"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Gross Loan
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <input
                            name="gross_loan"
                            type="text"
                            className="form-control"
                            id="gross_loan"
                            value={rates.gross_loan || ''}
                            onChange={handleInputChange}
                            
                          />
                        </div>
                      </div>

                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn text-uppercase  blue-gradient-bg-1 rounded-pill text-white fw-800 border-0"
                          
                        >
                          Save Changes
                        </button>
                      </div>
                      </Spin>
                    </form>
                  ) : (
                    <p>No rate information available to edit.</p>
                  )}
                </div>

                <div className="tab-pane fade profile-edit pt-3" id="faqs-edit">
                  {rates ? (
                    <form onSubmit={handleSubmitFaqs}>
                       <Spin spinning={loading}>
                      <div className="row mb-3">
                        <label
                          htmlFor="message"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Interest
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <textarea
                            name="interest"
                            className="form-control"
                            id="interest"
                            style={{ height: '200px' }}
                            value={faqs.interest || ''}
                            onChange={handleFaqsInputChange}
                            
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="interest"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Processing Time
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <textarea
                            name="procesing_time"
                            type="text"
                            className="form-control"
                            style={{ height: '200px' }}
                            id="procesing_time"
                            value={faqs.procesing_time || ''}
                            onChange={handleFaqsInputChange}
                            
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="insurance"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Loan Requirements
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <textarea
                            name="loan_requirements"
                            type="text"
                            className="form-control"
                            id="loan_requirements"
                            style={{ height: '200px' }}
                            value={faqs.loan_requirements || ''}
                            onChange={handleFaqsInputChange}
                            
                          />
                        </div>
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn text-uppercase  blue-gradient-bg-1 rounded-pill text-white fw-800 border-0"
                          
                        >
                          Save Changes
                        </button>
                      </div>
                      </Spin>
                    </form>
                  ) : (
                    <p>No rate information available to edit.</p>
                  )}
                </div>

                <div className="tab-pane fade profile-edit pt-3" id="messages-edit">
                  {rates ? (
                    <form onSubmit={handleSubmitMessages}>
                       <Spin spinning={loading}>
                      <div className="row mb-3">
                        <label
                          htmlFor="message"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Completion Message
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <textarea
                            name="completion_msg"
                            className="form-control"
                            id="interest"
                            style={{ height: '200px' }}
                            value={messages.completion_msg || ''}
                            onChange={handleMessageInputChange}
                            
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="message"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Welcome Message
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <textarea
                            name="welcome_msg"
                            className="form-control"
                            id="interest"
                            style={{ height: '200px' }}
                            value={messages.welcome_msg || ''}
                            onChange={handleMessageInputChange}
                             maxLength="255"
                            
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="message"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Welcome Footer
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <textarea
                            name="welcome_footer"
                            className="form-control"
                            id="interest"
                            style={{ height: '200px' }}
                            value={messages.welcome_footer || ''}
                            onChange={handleMessageInputChange}
                             maxLength="50"
                            
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="message"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Talk To Agent
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <textarea
                            name="talk_to_agent_msg"
                            className="form-control"
                            id="interest"
                            style={{ height: '200px' }}
                            value={messages.talk_to_agent_msg || ''}
                            onChange={handleMessageInputChange}
                            
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="message"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          Private Sector Message
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <textarea
                            name="private_sector_msg"
                            className="form-control"
                            id="interest"
                            style={{ height: '200px' }}
                            value={messages.private_sector_msg || ''}
                            onChange={handleMessageInputChange}
                            
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="message"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          SME Message
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <textarea
                            name="sme_msg"
                            className="form-control"
                            id="interest"
                            style={{ height: '200px' }}
                            value={messages.sme_msg || ''}
                            onChange={handleMessageInputChange}
                            
                          />
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label
                          htmlFor="message"
                          className="col-md-4 col-lg-3 col-form-label"
                        >
                          ZNA Message
                        </label>
                        <div className="col-md-8 col-lg-9">
                          <textarea
                            name="zna_msg"
                            className="form-control"
                            id="interest"
                            style={{ height: '200px' }}
                            value={messages.zna_msg || ''}
                            onChange={handleMessageInputChange}
                            
                          />
                        </div>
                      </div>

                      <div className="text-center">
                        <button
                          type="submit"
                          className="btn text-uppercase  blue-gradient-bg-1 rounded-pill text-white fw-800 border-0"
                          
                        >
                          Save Changes
                        </button>
                      </div>
                      </Spin>
                    </form>
                  ) : (
                    <p>No rate information available to edit.</p>
                  )}
                </div>
                </>
                )}
                
                <div className={`tab-pane fade ${!passwordStatus ? 'show active' : ''} pt-3 `} id="profile-change-password">
                  <form onSubmit={handleSubmitPassword}>
                    <Spin spinning={loading}>
                    <div className="row mb-3">
                      <label
                        htmlFor="old_password"
                        className="col-md-4 col-lg-3 col-form-label"
                      >
                        Old Password
                      </label>
                      <div className="col-md-8 col-lg-9">
                        <input
                          name="old_password"
                          type="password"
                          className="form-control"
                          id="old_password"
                          value={passwords.old_password}
                          onChange={handlePasswordChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label
                        htmlFor="new_password"
                        className="col-md-4 col-lg-3 col-form-label"
                      >
                        New Password
                      </label>
                      <div className="col-md-8 col-lg-9">
                        <input
                          name="new_password"
                          type="password"
                          className="form-control"
                          id="new_password"
                          value={passwords.new_password}
                          onChange={handlePasswordChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label
                        htmlFor="confirm_password"
                        className="col-md-4 col-lg-3 col-form-label"
                      >
                        Confirm Password
                      </label>
                      <div className="col-md-8 col-lg-9">
                        <input
                          name="confirm_password"
                          type="password"
                          className="form-control"
                          id="confirm_password"
                          value={passwords.confirm_password}
                          onChange={handlePasswordChange}
                          required
                        />
                      </div>
                    </div>

                    <div className="text-center">
                      <button type="submit" className="btn text-uppercase blue-gradient-bg-1 rounded-pill text-white fw-800 border-0">
                        Change Password
                      </button>
                    </div>
                    </Spin>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Settings;

