import React, { useEffect, useState } from 'react'
import IMG from '../../../images/sample.jpeg'
import { Config } from '../../../config';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Notifications } from '../../../utils';
import { Box,Button,Modal, TextField } from '@mui/material';
import { Form } from 'antd';

function ViewApplication() {
  const { id } = useParams();
  const config = Config();
  const [application,setApplication]= useState([])
  const [loading, setLoading]=useState(false)
  const [openModal, setOpenModal] = useState(false);
  const [reason, setReason] = useState({
    reason: '',

  });

  const [formData, setFormData] = useState({
    title: application.title || '',
    first_name: application.first_name || '',
    surname: application.surname || '',
    national_id: application.national_id || '',
    mobile_number: application.mobile_number || '',
    date_of_birth: application.date_of_birth || '',
    educational_level: application.educational_level || '',
    marital_status: application.marital_status || '',
    email: application.email || '',
    house_number: application.house_number || '',
    street_name: application.street_name || '',
    suburb: application.suburb || '',
    city: application.city || '',
    country: application.country || '',
    residential_area: application.residential_area || '',
    property_ownership: application.property_ownership || '',
    other_assets_owned: application.other_assets_owned || '',
    bank_name: application.bank_name || '',
    branch_name: application.branch_name || '',
    account_name: application.account_name || '',
    branch_code: application.branch_code || '',
    account_number: application.account_number || '',
    ecocash_number: application.ecocash_number || '',
    nok_title: application.nok_title || '',
    nok_full_name: application.nok_full_name || '',
    nok_national_id: application.nok_national_id || '',
    nok_relationship: application.nok_relationship || '',
    nok_mobile_number: application.nok_mobile_number || '',
    nok_address: application.nok_address || '',
    basic_salary: application.basic_salary || '',
    net_salary: application.net_salary || '',
    amount_required: application.amount_required || '',
    tenure: application.tenure || '',
    loan_purpose: application.loan_purpose || '',
    existing_loan_amount: application.existing_loan_amount || '',
    employment_sector: application.employment_sector || '',
    occupation: application.occupation || '',
    employee_number: application.employee_number || '',
    employer_phone_number: application.employer_phone_number || '',
    payslip: application.payslip || '',
    proof_of_residence: application.proof_of_residence || '',
    national_id: application.national_id || '',
    signature: application.signature || '',
    selfie: application.employer_phone_number || '',
  });

  const handleInputChange = (event) => {
    const { name, files, value } = event.target;
  
    if (files && files.length > 0) {
      setFormData(prevState => ({
        ...prevState,
        [name]: files[0]  
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value  
      }));
    }
  };
  
  
  const handlereasonChange = (e) => {
    setReason({
      ...reason,
      [e.target.name]: e.target.value,
    });
  };

  const headers = {
    headers: {
      Authorization: `Bearer ${config.token}`,
      'Content-Type': 'multipart/form-data'
    },
    mode: 'cors',
  };

  useEffect(() => {
    getLoanApplicationDetails();
  }, []);


  const getLoanApplicationDetails = () => {
    let url = `${config.base_url}view_application_details/${id}/`;
    axios
      .get(url, headers)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setApplication(res.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        setApplication(null);
      });
  };

  const updateLoanApplicationDetails = () => {
    const url = `${config.base_url}update-loan/${id}/`;
  
    axios.patch(url, formData, headers
    )
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setApplication(res.data);
          Notifications('success', 'Success', 'Application updated successfully');
          getLoanApplicationDetails();
        }
      })
      .catch((error) => {
        Notifications('error', 'Error', 'Failed to update application');
        setLoading(false);
        setApplication(null);
      });
  };
    
  const handleSubmit = (e) => {
    e.preventDefault(); 
    updateLoanApplicationDetails();
  };
  

  const approveApplication = () => {
    setLoading(true)
    let url = `${config.base_url}approve-application/${id}/`;
    axios
      .get(url, headers)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          Notifications('success', 'Success', 'Application approved successfully');
        }
      })
      .catch((error) => {
        setLoading(false);
        Notifications('error', 'Error', 'Failed to approve application');
      });
  };

  const rejectApplication = () => {
    setLoading(true)
    let url = `${config.base_url}approve-application/${id}/`;

    axios
      .post(url, {...reason},headers)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setOpenModal(false);
          Notifications('success', 'Success', 'Application approved successfully');
        }
      })
      .catch((error) => {
        setLoading(false);
        setOpenModal(false);
        Notifications('error', 'Error', 'Failed to approve application');
      });
  };



  const handleOpenModal = (e) => {
    e.preventDefault();
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };
  const getFullImageUrl = (path) => `${config.main_url}${path}`;

  return (
    <section className="section application">
      <div className="row">

        <div className="col-xl-5">

        <div class="card">
            <div class="card-body">
              <h5 class="card-title">Application Details</h5>

              <form>
                <div class="row mb-3">
                  <label for="inputTitle" class="col-sm-2 col-form-label">Title</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputTitle" onChange={handleInputChange} name='title'  placeholder={application.title}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputFirstName" class="col-sm-2 col-form-label">First Name</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputFirstName" onChange={handleInputChange} name='first_name' placeholder={application.first_name}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputLastName" class="col-sm-2 col-form-label">Last Name</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputLastName" onChange={handleInputChange} name='surname' placeholder={application.surname}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputNationalId" class="col-sm-2 col-form-label">National Id</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputNationalId" onChange={handleInputChange} name='national_id' placeholder={application.national_id}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputMobileNumber" class="col-sm-2 col-form-label">Mobile Number</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputMobileNumber" onChange={handleInputChange} name='mobile_number' placeholder={application.mobile_number}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputDateOfBirth" class="col-sm-2 col-form-label">Date of Birth</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputDateOfBirth" onChange={handleInputChange} name='date_of_birth' placeholder={application.date_of_birth}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputEducationLevel" class="col-sm-2 col-form-label">Education Level</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputEducationLevel" onChange={handleInputChange} name='educational_level' placeholder={application.educational_level}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputMaritalStatus" class="col-sm-2 col-form-label">Marital Status</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputMaritalStatus" onChange={handleInputChange} name='marital_status' placeholder={application.marital_status}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputEmail" class="col-sm-2 col-form-label">Email</label>
                  <div class="col-sm-10">
                    <input type="email" class="form-control" id="inputEmail" onChange={handleInputChange} name='email' placeholder={application.email}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputHouseNumber" class="col-sm-2 col-form-label">House Number</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputHouseNumber" onChange={handleInputChange} name='house_number' placeholder={application.house_number}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputStreetName" class="col-sm-2 col-form-label">Street Name</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputStreetName" onChange={handleInputChange} name='street_name' placeholder={application.street_name}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputSuburb" class="col-sm-2 col-form-label">Suburb</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputSuburb" onChange={handleInputChange} name='suburb' placeholder={application.suburb}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputCity" class="col-sm-2 col-form-label">City</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputCity" onChange={handleInputChange} name='city' placeholder={application.city}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputCountry" class="col-sm-2 col-form-label">Country</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputCountry" onChange={handleInputChange} name='country' placeholder={application.country}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputResidentialArea" class="col-sm-2 col-form-label">Residential Area</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputResidentialArea" value={application.residential_area} name='residential_area'/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputPropertyOwnership" class="col-sm-2 col-form-label">Property Ownership</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputPropertyOwnership" onChange={handleInputChange} name='property_ownership' placeholder={application.property_ownership}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputOtherAssetsOwned" class="col-sm-2 col-form-label">Other Assets Owned</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputOtherAssetsOwned" onChange={handleInputChange} name='other_assets_owned' placeholder={application.other_assets_owned}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputBankName" class="col-sm-2 col-form-label">Bank Name</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputBankName" onChange={handleInputChange} name='bank_name' placeholder={application.bank_name}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputBranchName" class="col-sm-2 col-form-label">Branch Name</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputBranchName" onChange={handleInputChange} name='branch_name' placeholder={application.branch_name}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputAccountName" class="col-sm-2 col-form-label">Account Name</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputAccountName" onChange={handleInputChange} name='account_name' placeholder={application.account_name}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputBranchCode" class="col-sm-2 col-form-label">Branch Code</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputBranchCode" onChange={handleInputChange} name='branch_code' placeholder={application.branch_code}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputAccountNumber" class="col-sm-2 col-form-label">Account Number</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputAccountNumber" onChange={handleInputChange} name='account_number' placeholder={application.account_number}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputEcocashNumber" class="col-sm-2 col-form-label">Ecocash Number</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputEcocashNumber" onChange={handleInputChange} name='ecocash_number' placeholder={application.ecocash_number}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputNokTitle" class="col-sm-2 col-form-label">NOK Title</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputNokTitle" onChange={handleInputChange} name='nok_title' placeholder={application.nok_title}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputNokFullName" class="col-sm-2 col-form-label">NOK Full Name</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputNokFullName" onChange={handleInputChange} name='nok_full_name' placeholder={application.nok_full_name}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputNokNationalId" class="col-sm-2 col-form-label">NOK National Id</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputNokNationalId" onChange={handleInputChange} name='nok_national_id' placeholder={application.nok_national_id}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputNokDateOfBirth" class="col-sm-2 col-form-label">NOK Date of Birth</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputNokDateOfBirth" onChange={handleInputChange} name='nok_date_of_birth' placeholder={application.nok_date_of_birth}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputNokRelationship" class="col-sm-2 col-form-label">NOK Relationship</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputNokRelationship" onChange={handleInputChange} name='nok_relationship' placeholder={application.nok_relationship}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputNokMobileNumber" class="col-sm-2 col-form-label">NOK Mobile Number</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputNokMobileNumber" onChange={handleInputChange} name='nok_mobile_number' placeholder={application.nok_mobile_number}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputNokAddress" class="col-sm-2 col-form-label">NOK Address</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputNokAddress" onChange={handleInputChange} name='nok_address' placeholder={application.nok_address}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputBasicSalary" class="col-sm-2 col-form-label">Basic Salary</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputBasicSalary" onChange={handleInputChange} name='basic_salary' placeholder={application.basic_salary}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputNetSalary" class="col-sm-2 col-form-label">Net Salary</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputNetSalary" onChange={handleInputChange} name='net_salary' placeholder={application.net_salary}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputEmploymentSector" class="col-sm-2 col-form-label">Employment Sector</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputEmploymentSector" onChange={handleInputChange} name='employment_sector' placeholder={application.employment_sector}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputOccupation" class="col-sm-2 col-form-label">Occupation</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputOccupation" onChange={handleInputChange} name='occupation' placeholder={application.occupation}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputEmployeeNumber" class="col-sm-2 col-form-label">Employee Number</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputEmployeeNumber" onChange={handleInputChange} name='employee_number' placeholder={application.employee_number}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputEmployerPhoneNumber" class="col-sm-2 col-form-label">Employer Phone Number</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputEmployerPhoneNumber" onChange={handleInputChange} name='employer_phone_number' placeholder={application.employer_phone_number}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputEmploymentDate" class="col-sm-2 col-form-label">Employment Date</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputEmploymentDate" onChange={handleInputChange} name='employment_date' placeholder={application.employment_date}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputRetirementDate" class="col-sm-2 col-form-label">Retirement Date</label>
                  <div class="col-sm-10">
                    <input type="text" class="form-control" id="inputRetirementDate" onChange={handleInputChange} name='retirement_date' placeholder={application.retirement_date}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputRetirementDate" class="col-sm-2 col-form-label">Payslip</label>
                  <div class="col-sm-10">
                    <input type="file" class="form-control" id="inputRetirementDate" onChange={handleInputChange} name='payslip' placeholder={application.payslip}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputRetirementDate" class="col-sm-2 col-form-label">Proof of Residence</label>
                  <div class="col-sm-10">
                    <input type="file" class="form-control" id="inputRetirementDate" onChange={handleInputChange} name='proof_of_residence' placeholder={application.proof_of_residence}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputRetirementDate" class="col-sm-2 col-form-label">National ID</label>
                  <div class="col-sm-10">
                    <input type="file" class="form-control" id="inputRetirementDate" onChange={handleInputChange} name='national_id' placeholder={application.national_id}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputRetirementDate" class="col-sm-2 col-form-label">Selfie</label>
                  <div class="col-sm-10">
                    <input type="file" class="form-control" id="inputRetirementDate" onChange={handleInputChange} name='selfie' placeholder={application.selfie}/>
                  </div>
                </div>
                <div class="row mb-3">
                  <label for="inputRetirementDate" class="col-sm-2 col-form-label">Signature</label>
                  <div class="col-sm-10">
                    <input type="file" class="form-control" id="inputRetirementDate" onChange={handleInputChange} name='signature' placeholder={application.selfie}/>
                  </div>
                </div>
                
                <div class="text-center">
                  <button onClick={approveApplication} disabled={loading} class="btn text-uppercase blue-gradient-bg-1 text-white fw-800  px-5">Approve</button>
                  <button onClick={handleOpenModal} class="btn text-uppercase green-gradient-bg-1 text-white fw-800  px-5">Reject</button>
                  <button onClick={handleSubmit}  class="btn text-uppercase orange-gradient-bg-1 text-white fw-800  px-5">Update</button>

                </div>
              </form>


            </div>
          </div>

        </div>
        <div className="col-xl-7">

          <div className="card">
            <div className="card-body application-card pt-4 d-flex flex-column align-items-center">
            <h5 className="card-title">Payslip</h5>
              <img src={getFullImageUrl(application.payslip)} alt="payslip" />
            </div>
          </div>
          <div className="card">
            <div className="card-body application-card pt-4 d-flex flex-column align-items-center">
            <h5 className="card-title">National ID</h5>
            <img src={getFullImageUrl(application.id_copy)} alt="National ID" />
            </div>
          </div>
          <div className="card">
            <div className="card-body application-card pt-4 d-flex flex-column align-items-center">
            <h5 className="card-title">Proof of Residence </h5>
            <img src={getFullImageUrl(application.proof_of_residence)} alt="Proof of Residence" />
            </div>
          </div>
          <div className="card">
            <div className="card-body application-card pt-4 d-flex flex-column align-items-center">
            <h5 className="card-title">Selfie </h5>
            <img src={getFullImageUrl(application.client_pic)} alt="National ID" />
            </div>
          </div>
          <div className="card">
            <div className="card-body application-card pt-4 d-flex flex-column align-items-center">
            <h5 className="card-title">Signature </h5>
            <img src={getFullImageUrl(application.client_signature)} alt="National ID" />
            </div>
          </div>

        </div>
      </div>
      <Modal open={openModal} onClose={handleCloseModal}>
            <Box sx={{ 
                position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
                width: 600, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: '8px',
            }}>
                <h2>Reason For Rejection</h2>
                <Form layout="vertical">
                <TextField
                    label="Reason"
                    name="reason"
                    fullWidth
                    margin="normal"
                    // value={reason.reason}
                    onChange={handlereasonChange}
                />
                
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={rejectApplication}
                    style={{ marginTop: '16px' }}
                    className="btn text-uppercase blue-gradient-bg-1 fw-800 border-0 px-5"
                  >
                    Reject Application
                  </Button>
                </div>

                </Form>
            </Box>
            </Modal>
    </section>
  )
}

export default ViewApplication

