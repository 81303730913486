import React, { useState, useEffect } from 'react'
import Img from '../../images/logo.png'
import { Form, Modal, Spin } from 'antd'
import { useAxios } from '../../hooks';
import { Notifications, setLocalStorage } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { Config } from '../../config';
import axios from 'axios';
function Login() {

  const [isLoading, setIsLoading] = useState(false);
  const [loginResponse, loginStatus, userLogin] = useAxios("post");
  const [isOtpModalVisible, setIsOtpModalVisible]= useState(false);
  const [isOtpLoading, setIsOtpLoading] = useState(false)
  const [login, setLogin] = useState({
    email: '',
    password: '',
  });
  const config = Config();


  const navigate = useNavigate();

  const headers = {
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };

  const handleLoginChange = (e) => {
    const { name, value } = e.target;
    setLogin({ ...login, [name]: value });
  };
  useEffect(() => {
    if (loginResponse) {
      try {
        if (loginStatus) {
          console.log(loginResponse);
          setLocalStorage("userInfo", {
            loginToken: loginResponse.data.access,
            user_id: loginResponse.data.user_id,
            is_superuser: loginResponse.data.is_superuser,
            user_type: loginResponse.data.user_type,
            province: loginResponse.data.province,
            force_password_change: loginResponse.data.force_password_change,
            isLoggedIn: false,
          });
          setIsLoading(false)
          Notifications(
            'success',
            'Success',
            'We have sent an OTP to your whatsapp. Please check'
          )
          setIsOtpModalVisible(true)
        } else {
          setIsLoading(false);
          Notifications(
            "error",
            "Error",
            "Failed to login with given credentials"
          );
        }
      } catch (error) {
        setIsLoading(false)
        Notifications(
          "error",
          "Error",
          "Failed to login with given credentials"
        );
      }
    }
  }, [loginStatus]);
  

  const onFinish = (values) => {
    setIsLoading(true);
    const loginUrl = "token/"
    const payload = JSON.stringify({
      email: login.email,
      password: login.password,
    });
    userLogin(loginUrl, payload);
  };

  const verifyOTP = (values) => {
    setIsOtpLoading(true);
  
    const payload = JSON.stringify({
      email: login.email,
      otp: values.otp,
    });
  
    const url = `${config.main_url}accounts/verify-otp/`;
  
    axios
      .post(url, payload, headers)
      .then((res) => {
        if (res.status === 200) {
          const existingUserInfo = JSON.parse(localStorage.getItem("userInfo"));
          if (!existingUserInfo.force_password_change) {
            setLocalStorage("userInfo", {
              ...existingUserInfo,
              isLoggedIn: true,
            });
            navigate('/');
          }else{
            setLocalStorage("userInfo", {
              ...existingUserInfo,
              isLoggedIn: false,
            });
            navigate('/change-password');
            
          }
          
        }
      })
      .catch((error) => {
        Notifications('error', 'Error', 'Invalid OTP');
        setIsLoading(false);
        setIsOtpLoading(false);
        setIsOtpModalVisible(false);
      });
  };
  


  const onFinishFailed = (errorInfo) => {};
  return (
    <main>
    <div className="container">

      <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

              <div className="d-flex justify-content-center py-4">
                <a href="/" className="logo d-flex align-items-center w-auto">
                  <img src={Img} alt="logo"/>
                </a>
              </div>

              <div className="card mb-3">

                <div className="card-body">

                  <div className="pt-4 pb-2">
                    <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                    <p className="text-center small">Enter your username & password to login</p>
                  </div>
                  <Spin spinning={isLoading}>
                  <Form 
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    className="row g-3 needs-validation" 
                    >

                    <div className="col-12">
                        <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'Email required!',
                            },
                            ]}
                            name="email"
                        >
                            <input 
                            value={login.email}
                            onChange={handleLoginChange}
                            type="email" name="email" className="form-control" id="yourUsername" placeholder='Email Address'/>
                        </Form.Item>
                    </div>

                    <div className="col-12">
                      <label for="yourPassword" className="form-label">Password</label>
                      <Form.Item
                            rules={[
                            {
                                required: true,
                                message: 'Password Required',
                            },
                            ]}
                            name="password"
                        >
                        <input 
                        value={login.password}
                        onChange={handleLoginChange}
                        type="password" name="password" className="form-control" id="yourPassword" placeholder='password' />
                        </Form.Item>
                      <div className="invalid-feedback">Please enter your password!</div>
                    </div>

                    <div className="col-12">
                      <button className="btn text-uppercase w-100 blue-gradient-bg-1 rounded-pill text-white fw-800 border-0 px-5" 
                        type="submit">
                        Login</button>
                    </div>
                    <div class="col-12">
                      <p class="small mb-0">Forgot password? <a href="/forgot-password">Click to reset password</a></p>
                    </div>
                  </Form>
                </Spin>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

    </div>
    <Modal
        style={{ marginTop: '20px' }}
        visible={isOtpModalVisible}
        footer={false}
        closable={true}
        onCancel={() => {
          setIsOtpModalVisible(false)
        }}
        width={700}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content border-0 rounded-0">
            <div className="modal-header green-gradient-bg-1 border-0 rounded-0">
              <h5
                className="modal-title text-white text-uppercase fs-16px fw-700"
                id="add-productLabel"
              >
                Enter verification code
              </h5>
            </div>
            <div className="modal-body border-0 rounded-0">
              <Spin spinning={isOtpLoading}>
                <Form name="basic" onFinish={verifyOTP} onFinishFailed={onFinishFailed} autoComplete="off">
                  <div className="row">
                    <div className="col-12 col-md-12 mt-4">
                      <label className="text-green-2 fw-700 px-0">OTP</label>
                      <Form.Item
                        name="otp"
                        rules={[
                          {
                            required: true,
                            message: 'Please input otp!',
                          },
                        ]}
                      >
                        <input
                          className="px-0 text-green fw-500 form-control form-control-sm border-3 bg-transparent border-bottom-green-1 rounded-0"
                          type="number"
                        />
                      </Form.Item>
                    </div>

                    <div className="mt-3 text-center otp-button">
                      <input
                        style={{ marginRight: '5px' }}
                        className="btn text-uppercase blue-gradient-bg-1 rounded-0 text-white fw-800 border-0 px-5"
                        type="submit"
                        value="submit"
                      />

                      {/* <a
                        href
                        onClick={() => {
                          verifyOTP()
                        }}
                        className="btn text-uppercase blue-gradient-bg-1 rounded-0 text-white fw-800 border-0 px-5"
                      >
                        Resend OTP
                      </a> */}
                    </div>
                  </div>
                </Form>
              </Spin>
            </div>
          </div>
        </div>
      </Modal>
  </main>
  )
}

Login.propTypes = {};

export default Login;
