import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { IconButton, Button, Modal, Box, TextField, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import axios from 'axios';
import moment from 'moment';
import { Config } from '../../../config';
import { Form, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getLocalStorage, Notifications } from '../../../utils';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import EditIcon from '@mui/icons-material/Edit';

const SystemUsers = () => {
  const config = Config();
  const navigate = useNavigate();
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState('');
  const isSuperuser = getLocalStorage("userInfo").user_type === 'super_user';
  const [editUser, setEditUser] = useState(null);


  const [newUser, setNewUser] = useState({
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    type: '',
  });

  const headers = {
    headers: {
      Authorization: `Bearer ${config.token}`,
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  };

  useEffect(() => {
    getSystemUsers();
    getProvinces()
  }, []);

  const getSystemUsers = (values = {}) => {
    let url = `${config.base_url}users/`;

    if (values.start_date) {
      url += `?start_date=${values.start_date}`;
    }
    if (values.end_date) {
      url += `&end_date=${values.end_date}`;
    }

    axios
      .get(url, headers)
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setApplications(Array.isArray(res.data) ? res.data : []);
        }
      })
      .catch((error) => {
        setLoading(false);
        setApplications([]);
      });
  };
  const getProvinces = () => {
    axios
      .get(`${config.base_url}provinces/`, headers)
      .then((res) => {
        if (res.status === 200) {
          setProvinces(res.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching provinces:', error);
      });
  };

  const handleAddUser = () => {
    setOpenModal(true);
  };



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (editUser) {
        setEditUser((prev) => ({ ...prev, [name]: value }));
    } else {
        setNewUser((prev) => ({ ...prev, [name]: value }));
    }
};

const handleCloseModal = () => {
  setOpenModal(false);
  setEditUser(null);
  setNewUser({
    first_name: '',
    last_name: '',
    phone_number: '',
    email: '',
    type: '',
  }); // Reset new user form fields
  setSelectedProvince(''); // Clear province selection
};

  const handleSubmit = () => {
    if (editUser) {
      axios
        .patch(`${config.base_url}update-user/${editUser.id}/`, { ...editUser, province: selectedProvince }, headers)
        .then((res) => {
          if (res.status === 200) {
            getSystemUsers();
            handleCloseModal()
            Notifications('success', 'Success', 'User Updated Successfully');
          }
        })
        .catch((error) => {
          Notifications('error', 'Error', 'Failed to update user');
        });
    } else {
      axios
        .post(`${config.base_url}create-user/`, { ...newUser, province: selectedProvince }, headers)
        .then((res) => {
          if (res.status === 201) {
            getSystemUsers();
            
            handleCloseModal();
            Notifications('success', 'Success', 'User Added Successfully');
          }
        })
        .catch((error) => {
          Notifications('error', 'Error', 'User with the given email already exists');
        });
    }
  };
  

const DeactivateUser = (id) => {
  axios
    .post(`${config.base_url}deactivate-user/`, { id }, headers)
    .then((res) => {
      if (res.status === 200) {
          getSystemUsers();
        setOpenModal(false);
        Notifications('success', 'Success', 'User Deactivated Successfully');
      }
    })
    .catch((error) => {
      Notifications('error', 'Error', 'Failed to deactivate user');
    });
};

const ActivateUser = (id) => {
  axios
    .post(`${config.base_url}activate-user/`, { id }, headers)
    .then((res) => {
      if (res.status === 200) {
          getSystemUsers();
        setOpenModal(false);
        Notifications('success', 'Success', 'User Activated Successfully');
      }
    })
    .catch((error) => {
      Notifications('error', 'Error', 'Failed to Activate user');
    });
};
const handleEditUser = (user) => {
  setEditUser(user);
  setSelectedProvince(user.province?.id || '');
  setOpenModal(true);
};

const columns = useMemo(() => {
  const baseColumns = [
    {
      accessorKey: 'first_name',
      header: 'First Name',
      size: 150,
    },
    {
      accessorKey: 'last_name',
      header: 'Last Name',
      size: 150,
    },
    {
      accessorKey: 'email',
      header: 'Email Address',
      size: 150,
    },
    {
      accessorKey: 'phone_number',
      header: 'Phone Number',
      size: 150,
    },
    {
      accessorKey: 'user_type',
      header: 'User Type',
      size: 150,
    },
    {
      accessorKey: 'province',
      header: 'Province',
      size: 150,
      Cell: ({ cell }) => cell.getValue()?.name || 'N/A',
    },
    {
      accessorKey: 'is_active',
      header: 'Active',
      size: 150,
      Cell: ({ cell }) => {
        const isActive = cell.getValue();
        return isActive ? (
          <CheckCircleIcon style={{ color: 'green' }} />
        ) : (
          <CancelIcon color="error" />
        );
      },
    },
  ];

  if (isSuperuser) {
    baseColumns.unshift({
      id: 'actions',
      header: 'Actions',
      size: 150,
      Cell: ({ row }) => {
        const { id, is_active } = row.original;
  
        return (
          <div style={{ display: 'flex', gap: '8px' }}>
            <Tooltip title="Edit User">
              <IconButton onClick={() => handleEditUser(row.original)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            {is_active ? (
              <Tooltip title="Deactivate User">
                <IconButton onClick={() => DeactivateUser(id)}>
                  <DeleteForeverIcon />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Activate User">
                <IconButton onClick={() => ActivateUser(id)}>
                  <TaskAltIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        );
      },
    });
  }
  

  return baseColumns;
}, [isSuperuser]);


  return (
    <Spin spinning={loading}>
      <main className="col-md-12 ms-sm-auto col-lg-12 p-0">
        <div className="row p-4 m-0">
          {isSuperuser &&(
          <div style={{ marginBottom: '16px', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddUser}
              className="btn text-uppercase blue-gradient-bg-1  text-white fw-800 border-0 px-5"
            >
              Add User
            </Button>
          </div>
          )}

          <MaterialReactTable columns={columns} data={applications || []} state={{ isLoading: loading }} />

          <Modal open={openModal} onClose={handleCloseModal}>
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                borderRadius: '8px',
              }}
            >
              <h2>{editUser ? 'Edit User' : 'Add New User'}</h2>
              <Form layout="vertical">
                <TextField
                  label="First Name"
                  name="first_name"
                  fullWidth
                  margin="normal"
                  value={editUser ? editUser.first_name : newUser.first_name}
                  onChange={handleInputChange}
                />
                <TextField
                  label="Last Name"
                  name="last_name"
                  fullWidth
                  margin="normal"
                  value={editUser ? editUser.last_name : newUser.last_name}
                  onChange={handleInputChange}
                />
                <TextField
                  label="Phone Number"
                  name="phone_number"
                  fullWidth
                  margin="normal"
                  value={editUser ? editUser.phone_number : newUser.phone_number}
                  onChange={handleInputChange}
                />
                <TextField
                  label="Email"
                  name="email"
                  fullWidth
                  margin="normal"
                  value={editUser ? editUser.email : newUser.email}
                  onChange={handleInputChange}
                />

                {/* User Type */}
                <TextField
                  select
                  name="user_type"
                  fullWidth
                  margin="normal"
                  value={editUser ? editUser.user_type : newUser.user_type}
                  onChange={handleInputChange}
                  SelectProps={{ native: true }}
                >
                  <option value="">Select User Type</option>
                  <option value="general_user">General User</option>
                  <option value="super_user">Super User</option>
                  <option value="master_user">Master User</option>
                  <option value="internal_audit_user">Internal Audit User</option>
                </TextField>

                {/* Province */}
                <TextField
                  select
                  name="province"
                  fullWidth
                  margin="normal"
                  value={editUser ? selectedProvince : newUser.province} // Use selectedProvince for editing
                  onChange={(e) => setSelectedProvince(e.target.value)} // Update selectedProvince on change
                  SelectProps={{ native: true }}
                >
                  <option value="">Select a province</option>
                  {provinces.map((province) => (
                    <option key={province.id} value={province.id}>
                      {province.name}
                    </option>
                  ))}
                </TextField>

                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    style={{ marginTop: '16px' }}
                    className="btn text-uppercase blue-gradient-bg-1 text-white fw-800 border-0 px-5"
                  >
                    {editUser ? 'Save Changes' : 'Add User'}
                  </Button>
                </div>
              </Form>
            </Box>
          </Modal>


        </div>
      </main>
    </Spin>
  );
};

export default SystemUsers;
